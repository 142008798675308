<template>
  <v-footer class="pa-10 my-20" elevation="2">
    <p id="footer-info-copyright">
      Text is available under the
      <a
        rel="license"
        href="//en.wikipedia.org/wiki/Wikipedia:Text_of_Creative_Commons_Attribution-ShareAlike_3.0_Unported_License"
        >Creative Commons Attribution-ShareAlike License</a
      ><a
        rel="license"
        href="//creativecommons.org/licenses/by-sa/3.0/"
        style="display:none;"
      />; additional terms may apply. By using this site, you agree to the
      <a href="//foundation.wikimedia.org/wiki/Terms_of_Use">Terms of Use</a>
      and
      <a href="//foundation.wikimedia.org/wiki/Privacy_policy">Privacy Policy</a
      >. Wikipedia® is a registered trademark of the
      <a href="//www.wikimediafoundation.org/">Wikimedia Foundation, Inc.</a>, a
      non-profit organization.
    </p>
  </v-footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>
